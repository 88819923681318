body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.tds-row input[type="checkbox"] {
  pointer-events: none !important;
  width: 15px !important;
}
.tds-row input[type="checkbox"]+label {
  color: black;
  font-weight: 700;
  font-size: 11px;
}
.tds-row input[type="checkbox"]:disabled+label {
  font-weight: 400;
  font-size: 12px;
  opacity: 0.5;
  color: black;
}